import { PriceDomain, PriceDto, WeekDay } from '@wix/bookings-uou-domain';
import {
  CatalogServiceDto,
  PaymentType as PaymentTypeV1,
  PricingPlanInfo,
  ServiceLocation,
  ServiceLocationType,
  ServicePaymentDto,
  ServiceScheduleHeaderDto,
  ServiceType as ServiceTypeV1,
  StaffMember,
} from '@wix/bookings-uou-types';
import { BusinessInfo as LegacyBusinessInfo } from '../../../legacy/types';
import { getDefaultTimezone } from '../timezone/timezone';
import {
  Location,
  LocationType,
  Payment,
  RateType,
  Schedule,
  Service as ServiceV2,
  ServiceType,
  StaffMember as StaffMemberV2,
  URLs,
} from '@wix/ambassador-bookings-services-v2-service/types';

export const getOfferedDays = (service: CatalogServiceDto, t: Function) => {
  const daysOrder =
    t('first-day-of-week') === t('week-day.sun')
      ? [
          WeekDay.SUNDAY,
          WeekDay.MONDAY,
          WeekDay.TUESDAY,
          WeekDay.WEDNESDAY,
          WeekDay.THURSDAY,
          WeekDay.FRIDAY,
          WeekDay.SATURDAY,
        ]
      : [
          WeekDay.MONDAY,
          WeekDay.TUESDAY,
          WeekDay.WEDNESDAY,
          WeekDay.THURSDAY,
          WeekDay.FRIDAY,
          WeekDay.SATURDAY,
          WeekDay.SUNDAY,
        ];

  let offeringDays = service.scheduleHeader.days;
  if (service.scheduleHeader.daysDetails) {
    offeringDays = service.scheduleHeader.daysDetails
      .filter((dayDetails) => {
        const shouldDisplayDay =
          !dayDetails.expirationDate ||
          new Date(dayDetails.expirationDate).getTime() > new Date().getTime();
        if (shouldDisplayDay) {
          return dayDetails;
        }
      })
      .map((dayDetails) => dayDetails.day);
  }

  if (offeringDays!.length === 7) {
    return t('working-hours.all-week');
  }
  const weekDayKeyPrefix: string =
    offeringDays!.length <= 2 ? 'long-week-day.' : 'short-week-day.';
  return daysOrder
    .filter((day) => offeringDays!.indexOf(day) > -1)
    .map((day) => t(weekDayKeyPrefix + day))
    .join(', ');
};

export const getFormattedStartDate = ({
  service,
  language,
  t,
  businessInfo,
}: {
  service: CatalogServiceDto;
  language: string;
  t: Function;
  businessInfo: LegacyBusinessInfo;
}) => {
  if (service.scheduleHeader.startDateAsUTC) {
    try {
      const startDate = new Date(service.scheduleHeader.startDateAsUTC);

      const isDateInCurrentYear = (date: Date) => {
        const now = new Date();
        return now.getFullYear() === date.getFullYear();
      };

      const isDateInThePast = (date: Date) => {
        const now = new Date();
        return date.getTime() < now.getTime();
      };

      const timeZone = getDefaultTimezone(businessInfo);
      const dateFormat: Intl.DateTimeFormatOptions = isDateInCurrentYear(
        startDate,
      )
        ? {
            month: 'short',
            day: 'numeric',
            timeZone,
          }
        : {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            timeZone,
          };

      const formattedStartDate = new Intl.DateTimeFormat(
        language,
        dateFormat,
      ).format(startDate);

      return isDateInThePast(startDate)
        ? `${t(
            'service.schedule.course.start.date.passed',
          )} ${formattedStartDate}`
        : `${t(
            'service.schedule.course.start.date.future',
          )} ${formattedStartDate}`;
    } catch (e) {
      return '';
    }
  }
  return '';
};

export const getPrice = ({
  service,
  language,
  locale,
  t,
}: {
  service: CatalogServiceDto;
  language: string;
  locale?: string;
  t: Function;
}): {
  displayedPrice: string;
  srOnlyPrice: string;
} => {
  const priceDto: PriceDto = {
    ...service.payment,
    displayTextForPlan: service.pricingPlanInfo
      ? service.pricingPlanInfo.displayText
      : '',
  };
  const priceDomain = new PriceDomain(
    priceDto,
    service.offeredAs,
    language,
    locale,
    true,
    (price) =>
      service.payment.isVariedPricing
        ? t('service.payment.from-price', { price })
        : price,
  );
  return {
    displayedPrice: priceDomain.text,
    srOnlyPrice: priceDomain.ariaLabel,
  };
};
const mapServiceTypeV1ToV2 = (serviceType: ServiceTypeV1): ServiceType => {
  switch (serviceType) {
    case ServiceTypeV1.INDIVIDUAL:
      return ServiceType.APPOINTMENT;
    case ServiceTypeV1.GROUP:
      return ServiceType.CLASS;
    case ServiceTypeV1.COURSE:
      return ServiceType.COURSE;
  }
};
const mapPaymentRateType = (payment: ServicePaymentDto): RateType => {
  if (payment.priceText) {
    return RateType.CUSTOM;
  } else if (payment.isFree || !payment.price) {
    return RateType.NO_FEE;
  } else if (payment.isVariedPricing) {
    return RateType.VARIED;
  } else {
    return RateType.FIXED;
  }
};
const mapPaymentToPaymentV2 = (
  payment: ServicePaymentDto,
  pricingPlanInfo: PricingPlanInfo,
): Payment => {
  const rateType = mapPaymentRateType(payment);
  const paymentV2: Payment = {
    rateType,
    pricingPlanIds: pricingPlanInfo.pricingPlans.map((plan) => plan.id),
  };
  if (rateType === RateType.FIXED) {
    paymentV2.fixed = {
      price: { value: `${payment.price}`, currency: payment.currency },
    };
  } else if (RateType.CUSTOM === rateType) {
    paymentV2.custom = {
      description: payment.priceText,
    };
  } else if (RateType.VARIED === rateType) {
    paymentV2.varied = {
      defaultPrice: { value: `${payment.price}`, currency: payment.currency },
      minPrice: {
        value: `${payment.minPrice?.price}`,
        currency: payment.minPrice?.currency,
      },
      maxPrice: {
        value: `${payment.maxPrice?.price}`,
        currency: payment.maxPrice?.currency,
      },
    };
  }
  paymentV2.options = {
    online: [PaymentTypeV1.ONLINE || PaymentTypeV1.BOTH].includes(
      payment.paymentType,
    ),
    inPerson: [PaymentTypeV1.OFFLINE || PaymentTypeV1.BOTH].includes(
      payment.paymentType,
    ),
  };
  return paymentV2;
};
const mapLocationType = (location: ServiceLocationType): LocationType => {
  switch (location) {
    case ServiceLocationType.OWNER_BUSINESS:
      return LocationType.BUSINESS;
    case ServiceLocationType.OWNER_CUSTOM:
      return LocationType.CUSTOM;
    case ServiceLocationType.CUSTOM:
      return LocationType.CUSTOMER;
    case ServiceLocationType.CLIENT_PLACE:
      return LocationType.CUSTOMER;
    default:
      return LocationType.BUSINESS;
  }
};
const mapLocationsToLocationV2 = (
  locations?: ServiceLocation[],
): Location[] | undefined => {
  return locations?.map((location: ServiceLocation) => {
    return {
      type: mapLocationType(location.type),
      business: {
        id: location.businessLocation?.id,
        name: location.businessLocation?.name,
        default: location.businessLocation?.default,
        address: location.businessLocation?.address,
      },
      custom: {
        address: {
          formattedAddress: location.address || location.locationText,
        },
      },
      calculatedAddress: {
        formattedAddress: location.address || location.locationText,
      },
    };
  });
};

const mapScheduleToScheduleV2 = (
  scheduleHeader: ServiceScheduleHeaderDto,
): Schedule => {
  return {
    firstSessionStart: new Date(scheduleHeader.startDate),
    lastSessionEnd: new Date(scheduleHeader.endDate),
    availabilityConstraints: {
      sessionDurations: [scheduleHeader.durationInMinutes!],
    },
  };
};

const mapStaffMemberToStaffMemberV2 = (
  staffMembers?: StaffMember[],
): StaffMemberV2[] => {
  if (!staffMembers) {
    return [];
  }
  return staffMembers?.map((staffMember) => {
    return {
      staffMemberId: staffMember.id,
      name: staffMember.name,
    };
  });
};

export const mapServiceToServiceV2 = ({
  service,
}: {
  service: CatalogServiceDto;
}): ServiceV2 => ({
  id: service.id,
  name: service.info.name,
  description: service.info.description,
  type: mapServiceTypeV1ToV2(service.type),
  sortOrder: service.order,
  category: { id: service.categoryId },
  payment: mapPaymentToPaymentV2(service.payment, service.pricingPlanInfo),
  locations: mapLocationsToLocationV2(service.info.locations),
  schedule: mapScheduleToScheduleV2(service.scheduleHeader),
  staffMemberIds: service.staffMemberIds,
  mainSlug: { name: service.urlName },
  staffMembers: mapStaffMemberToStaffMemberV2(service.staffMembers),
  urls: mapServiceUrls(service),
});

const mapServiceUrls = (service: CatalogServiceDto): URLs => ({
  servicePage: {
    url: service.fullUrl,
  },
});

export const isServiceDynamicPricing = (service: ServiceV2): boolean =>
  service.payment?.rateType === RateType.VARIED;
